<template>
    <div>
        <div style="position: relative" v-if="!planCheck && getted">
            <div class="d-flex flex-1 blured-div">
            <div class="wrapper">
                <div class="text-center">
                <h1 class="d-sm-none d-block">
                    Unlock Add Keyword Tracking with an Upgrade
                </h1>
                <h1 style="font-size: 3rem" class="d-none d-sm-block">
                    Unlock Add Keyword Tracking with an Upgrade
                </h1>
                <h4>
                    When you upgrade to Pro, you will be able to access Add keyword
                    Tracking, niches, and more tools from AppLead.
                </h4>
                </div>
                <b-button
                style="font-size: 2rem"
                variant="outline-success"
                to="/pages/plan-selection"
                >
                Upgrade Now
                </b-button>
            </div>
            </div>
        </div>
        <div v-if="!getted">
            <Loader/>
        </div>
        <div v-if="planCheck" class="d-flex justify-content-center mt-1">
            <b-card header-tag="header" footer-tag="footer">
                <template #header>
                    <div class="d-flex align-items-center w-100">
                        <b-button variant="outline-warning" to="/keyword-tracking">
                            <feather-icon icon="ArrowLeftCircleIcon" size="18" />
                        </b-button>
                        <h2 class="mb-0 ml-2">Add Tracked App</h2>
                    </div>
                    <small class="text-center w-100">Add app package name to track the app</small>
                </template>
                <hr>
                <div class="px-2">
                    <label>App Package Name :</label>
                    <b-form-group>
                        <b-form-input
                            id="package-name"
                            type="text"
                            placeholder="Enter app package name."
                            v-model="appPackage"
                            required
                        />
                        <small ref="appPackageError" class="text-danger app-package-error"></small>
                    </b-form-group>
                    <label>Server :</label><br>
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                            <span style="width:45%;" class="position-relative">
                                <img v-if="countryOptions.length !== 0" class="img-flag" :src="`https://flagsapi.com/${selectedCountry.toUpperCase()}/shiny/32.png`">    
                                <b-form-select v-b-tooltip.hover title="Countries" class="mr-1 ctr-lng-selects" v-model="selectedCountry">
                                    <option v-for="(option, index) in countryOptions" :key="index" :value="option.code">
                                        {{ option.code.toUpperCase() }}
                                    </option>
                                </b-form-select>
                            </span>  
                            <b-form-select v-b-tooltip.hover title="Languages" style="width: 45%;" class="ctr-lng-selects" v-model="selectedLang" :options="langOptions"/> 
                        </div>
                    </b-form-group>
                    <div>
                        <div class="d-flex">
                            <b-button 
                                v-b-tooltip.hover
                                title="Add Other Keyword"
                                style="padding: 4px 20px ;"
                                class="ml-auto d-flex"
                                variant="outline-success"
                                @click="addField"
                            >
                                <feather-icon icon="PlusCircleIcon" size="18" />
                            </b-button>
                        </div>
                        <div>
                            <label>Keywords :</label>
                            <span class="d-flex mt-1" v-for="(keyword,key) in keywords" :key="key">
                                <span class="w-100">
                                    <b-form-input   type="text" v-model="keyword.value"
                                            placeholder="Enter Keyword" required/>
                                    <small class="text-danger error-msg">{{ keyword.error }}</small>
                                </span>
                                <b-button 
                                    v-b-tooltip.hover
                                    title="Delete this field"
                                    style="padding: 9px 20px ;"
                                    class="ml-1 mb-1 d-flex "
                                    variant="outline-danger"
                                    @click="deleteField(keyword)"
                                >
                                    <feather-icon icon="DeleteIcon" size="18" />
                                </b-button>
                            </span>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <div class="d-flex justify-content-center w-100">
                        <b-button v-if="isLoading" class="loading-button" variant="primary" disabled>
                            <b-spinner small></b-spinner>
                            <span class="pl-1">Loading...</span>
                        </b-button>
                        <b-button v-else variant="primary" @click="addApp"> Add App </b-button>
                    </div>
                </template>
            </b-card>
        </div>
    </div>
</template>

<script>
// Import necessary components and styles
import { BCard, BCardText, BButton,BFormGroup, BFormInput, BFormSelect, VBTooltip, VBHover, BSpinner } from "bootstrap-vue";
import countriesJson from "../../assets/jsons/countries.json" 
import langsJson from "../../assets/jsons/langs.json" 
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { connectApi } from "@/helper/connectApi";
import Loader from "@/components/Loader/Loader.vue";

export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BFormInput,
        BFormSelect,
        VBTooltip,
        BFormGroup,
        VBHover,
        Ripple,
        BSpinner,
        Loader
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data(){
        return{
            isLoading:false,
            appPackage:"",
            selectedLang: "fr-FR",
            langOptions: [],
            selectedCountry: "ma",
            countryOptions: [],
            idField:1,
            getted: false,
            planCheck: null,
            keywords:[
                { id: 1, value: "", error: "" },
            ],
        }
    },
    mounted(){
        this.getCountries();
        this.checkUserPlan();
    },
    methods:{
        addApp(){
            if(this.appPackage.trim()){
                this.$refs.appPackageError.textContent = "";

                let isValidKeywords = true;
                this.keywords.forEach((keyword) => {
                    if (!keyword.value.trim()) {
                        keyword.error = "Keyword field is required";
                        isValidKeywords = false;
                    } else {
                        keyword.error = "";
                    }
                });

                if (isValidKeywords && this.appPackage.trim()) {
                    this.isLoading = true;
                    const response = connectApi(`https://scx.clicklab.app/api/apps/${this.appPackage}/`);
                    response.then((response) => {
                        const { status } = response.data;
                        if (status === "Unauthorized") {
                            deleteAccessTokenCookie();
                            this.$root.$emit("bv::show::modal", "modal-session-app");
                            return;
                        }
                        const data = response.data;
                        return data;
                    })
                    .then((data) => {
                        if(data){
                            const newTab = this.keywords.map(elem=>elem.value)

                            const formData = {
                                title: data.title,
                                appId : this.appPackage,
                                country : this.selectedCountry,
                                lang : this.selectedLang,
                                keywords : newTab,
                            }

                            axios.post('/create-tracked-apps',formData)
                            .then(({data})=>{
                                this.isLoading = false;
                                if(data.status == "success"){
                                    this.showToast("success", "CheckIcon", "success", data.message);
                                }else{
                                    this.showToast(data.status, "AlertTriangleIcon", data.status, data.message);
                                }
                            })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.showToast(
                            "warning",
                            "AlertTriangleIcon",
                            "Warning",
                            "Please make sure the Package name You've eneterd is correct!"
                        );
                    });

                } else {
                    this.showToast("warning", "AlertTriangleIcon", "Warning", "All fields is required");
                }
            }else{
                this.showToast("warning", "AlertTriangleIcon", "Warning", "All fields is required");
                this.$refs.appPackageError.textContent = "App Package field is required"
            }
        },
        addField(){
            if(this.keywords.length < 5){
                this.idField += 1
                this.keywords.push({id : this.idField, value:"",  error: ""})
            }else{
                this.showToast("warning", "AlertTriangleIcon", "Warning", "You can't add more then 5 Keywords");
            }
        },
        deleteField(item){
            if(this.keywords.length > 1){
                this.keywords = this.keywords.filter((elem)=>elem.id !== item.id)
            }else{
                this.showToast("warning", "AlertTriangleIcon", "Warning", "You can't remove this field");
            }
        },
        getCountries(){
            this.countryOptions = countriesJson.countries
            this.langOptions = langsJson.langs

            //-------set lang based on browser lang
            const langHeader = navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage;
            const langs = langHeader.split(',');
            const currLang = langs.map((lang)=>lang.trim());
            const isLangInArray = this.langOptions.some(country=>country.value === currLang[0])
            if(isLangInArray){
                this.selectedLang = currLang[0];
            }else{
                this.selectedLang = "fr-FR";
            }
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title,
                icon,
                text,
                variant,
                },
            });
        },
        checkUserPlan() {
            axios.post("/checkUserSubscription")
            .then(({ data }) => {
                this.getted = true;
                this.planCheck = true;
            }).catch((err) => {
                this.planCheck = false;
                this.getted = true;
            });
        },
    }
};
</script>
<style scoped>
.blured-div {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.192);
  opacity: 0.;
  width: 100% !important;
  height: 80vh !important;
  z-index: 100;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.loading-button{
    cursor: not-allowed !important;
}
.loading-button span:nth-child(2){
    position: relative;
    top: 1px;
}
.img-flag{
    position: absolute;
    top: 3px;
    right: 45px;
    cursor: pointer;
    pointer-events: none;
}
.error-msg{
    position: absolute;
}
.card-header{
    padding-bottom: 0;
}
</style>
