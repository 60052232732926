const { getAccessTokenFromCookie } = require("@/auth/utils");
const axios = require("axios");
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return error.data;
    }
);
module.exports = {
    connectApi: async (url) => {
        try {
            const token = getAccessTokenFromCookie()[0];
            const cookieData = getAccessTokenFromCookie()[1];
            //   const apiUrl = url.replace('https://scx.clicklab.app/api/', 'https://api.appsmanager.co/api/');
            const apiUrl = url.replace("scx", `sc${cookieData.server}`);
            //   const apiUrl = url.replace('https://scx.clicklab.app/api/',`http://127.0.0.1:3000/api/`);
            const params = `?tokenV2=${token}`;
            const headers = {
                Authorization: token,
            };
            const response = await axios.get(apiUrl, { headers });
            return response;
        } catch (error) {
            console.error("Error connecting to the API:", error);
        }
    },
};
